/**
 * Preferred default settings
 */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

img {
  display: block;
}

button {
  cursor: pointer;
}

/**
 * Custom Properties
 */
:root {
  /* color */
  --color-primary: rgb(103, 174, 224);
  --color-primary-dark: color-mix(in srgb, rgb(103, 174, 224) 100%, black 15%);
  --color-primary-light: color-mix(in srgb, rgb(103, 174, 224) 100%, white 90%);
  --color-secondary: rgb(242, 157, 56);
  --color-black: rgba(0, 0, 0);
  --color-white: rgba(255, 255, 255);
  --color-grey-01: hsl(0, 0%, 97%);
  --color-grey-02: hsl(0, 0%, 90%);
  --color-grey-03: hsl(0, 0%, 70%);

  /* spacing */
  --spacer: 6px;
  --space-xs: var(--spacer);
  --space-s: calc(var(--spacer) * 2);
  --space-m: calc(var(--spacer) * 3);
  --space-l: calc(var(--spacer) * 4);
  --space-xl: calc(var(--spacer) * 5);

  --spacer-rel: 0.3em;
  --space-rel-xs: var(--spacer-rel);
  --space-rel-s: calc(var(--spacer-rel) * 2);
  --space-rel-m: calc(var(--spacer-rel) * 3);

  --padding-default: var(--space-l);
  --padding-mobile-overlap: var(--space-m);
  --aside-max-width: 440px;
  --details-max-width: 600px;


  /* text */
  --font-size: 1rem;
  --font-family: 'Inter v', 'Helvetica', 'Arial', sans-serif;
  --font-big: normal 400 2em var(--font-family);
  --font-h1: normal 400 1.5em var(--font-family);
  --font-regular: normal 400 1em var(--font-family);
  --font-small: normal 400 0.8em var(--font-family);
  --font-tiny: normal 400 0.7em var(--font-family);

  /* effects */
  --card-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  --card-border-radius: 3px;
  --icon-active-filter: brightness(0) invert(1);
}

/**
 * Fonts
 */
/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter v';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url('../assets/fonts/Inter.var.woff2?v=3.19') format('woff2');
}


