html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

img {
  display: block;
}

button {
  cursor: pointer;
}

:root {
  --color-primary: #67aee0;
  --color-primary-dark: #5a97c3;
  --color-primary-light: #afd4ef;
  --color-secondary: #f29d38;
  --color-black: #000;
  --color-white: #fff;
  --color-grey-01: #f7f7f7;
  --color-grey-02: #e6e6e6;
  --color-grey-03: #b3b3b3;
  --spacer: 6px;
  --space-xs: var(--spacer);
  --space-s: calc(var(--spacer) * 2);
  --space-m: calc(var(--spacer) * 3);
  --space-l: calc(var(--spacer) * 4);
  --space-xl: calc(var(--spacer) * 5);
  --spacer-rel: .3em;
  --space-rel-xs: var(--spacer-rel);
  --space-rel-s: calc(var(--spacer-rel) * 2);
  --space-rel-m: calc(var(--spacer-rel) * 3);
  --padding-default: var(--space-l);
  --padding-mobile-overlap: var(--space-m);
  --aside-max-width: 440px;
  --details-max-width: 600px;
  --font-size: 1rem;
  --font-family: "Inter v", "Helvetica", "Arial", sans-serif;
  --font-big: normal 400 2em var(--font-family);
  --font-h1: normal 400 1.5em var(--font-family);
  --font-regular: normal 400 1em var(--font-family);
  --font-small: normal 400 .8em var(--font-family);
  --font-tiny: normal 400 .7em var(--font-family);
  --card-shadow: 0 0 5px #0003;
  --card-border-radius: 3px;
  --icon-active-filter: brightness(0) invert(1);
}

@font-face {
  font-family: Inter v;
  font-weight: 100 900;
  font-display: swap;
  src: url("Inter.var.5f2f8893.woff2") format("woff2");
}

@media (hover: none), (hover: on-demand) {
  body {
    -webkit-user-select: none !important;
    user-select: none !important;
  }
}

.center {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.card {
  border-radius: var(--card-border-radius);
  font: var(--font-regular);
  padding: var(--space-rel-s);
  box-shadow: var(--card-shadow);
  background: #fff;
  border: none;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 60px;
  color: #000;
  background-color: var(--color-black);
  color: var(--color-white);
  font-weight: bold;
  font: var(--font-regular);
  text-align: center;
  z-index: 1;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
}

.lds-dual-ring {
  width: 80px;
  height: 80px;
  margin: auto;
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  width: 64px;
  height: 64px;
  border: 6px solid var(--color-primary);
  border-color: #fff var(--color-primary);
  border-radius: 50%;
  margin: 8px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.autocomplete {
  display: inline-block;
  position: relative;
}

input {
  background-color: #f1f1f1;
  border: 1px solid #0000;
  padding: 10px;
  font-size: 16px;
}

input[type="input"] {
  width: 100%;
  background-color: #f1f1f1;
}

input[type="submit"] {
  color: #fff;
  cursor: pointer;
  background-color: #1e90ff;
}

.autocomplete-items {
  z-index: 99;
  border: none;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px;
}

.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.autocomplete-active {
  color: #fff;
  background-color: #1e90ff !important;
}

#app {
  width: 100vw;
  height: 100vh;
  font: normal 400 var(--font-size) var(--font-family);
  color: var(--color-black);
  flex-wrap: nowrap;
  display: flex;
  overflow: hidden;
}

#app[data-aside-closed] #aside {
  width: 0;
}

#app[data-aside-closed] #map__close:after {
  background: none;
}

#app .icon {
  width: 20px;
}

#aside {
  width: calc(100vw - var(--padding-mobile-overlap));
  flex-direction: column;
  flex: none;
  transition: width .3s ease-out;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

#aside #aside__header, #aside #aside__content {
  width: calc(100vw - var(--padding-mobile-overlap));
  max-width: var(--aside-max-width);
}

@media (min-width: 800.001px) {
  #aside, #aside #aside__header, #aside #aside__content {
    width: var(--aside-max-width);
  }
}

#aside__header {
  position: relative;
}

#aside__header nav {
  padding: var(--padding-default);
  gap: 5px;
  display: flex;
  overflow: scroll;
}

#aside__header a {
  --border-width: .15em;
  font: var(--font-regular);
  border: solid var(--border-width) var(--color-black);
  padding: calc(var(--space-rel-s)  - var(--border-width)) var(--space-rel-m);
  color: inherit;
  border-radius: 3em;
  text-decoration: none;
}

#aside__header a:hover {
  background: var(--color-grey-01);
}

#aside__header a:active, #aside__header a[data-selected] {
  background: var(--color-black);
  color: var(--color-white);
}

:is(#aside__header a:active, #aside__header a[data-selected]) .icon {
  filter: var(--icon-active-filter);
}

#aside__header:before, #aside__header:after {
  content: "";
  height: 100%;
  width: var(--padding-default);
  background: linear-gradient(90deg, #fff 0%, #fff0 100%);
  display: block;
  position: absolute;
  top: 0;
}

#aside__header:after {
  right: 0;
  transform: rotate(180deg);
}

#aside__content {
  padding: var(--padding-default);
  flex: 1;
}

#aside__content h1 {
  font: var(--font-h1);
}

#aside__content p {
  font: var(--font-regular);
}

#aside__content label {
  padding: var(--padding-default);
  background: var(--color-grey-01);
  border-bottom: solid 1px var(--color-grey-02);
  display: block;
}

#map {
  box-shadow: var(--card-shadow);
  flex: auto;
  position: relative;
}

#map #map__close {
  padding-top: var(--padding-default);
  z-index: 100;
  height: 100%;
  float: right;
  width: var(--padding-mobile-overlap);
  transition: background .3s ease-out;
  position: absolute;
  top: 0;
}

#map #map__close:after {
  content: "";
  width: 4px;
  height: 15vh;
  min-height: 100px;
  left: calc(var(--padding-mobile-overlap) / 2);
  background: #0000001a;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

#map #map__close #map__close__btn {
  background: var(--color-black);
  box-shadow: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transform: translateX(-100%);
}

#map #map__close #map__close__btn img {
  filter: var(--icon-active-filter);
}

#map__contents {
  height: 100%;
  width: 100vw;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(min-content, 400px) 1fr auto;
  display: grid;
  position: relative;
}

@media (min-width: 800.001px) {
  #map__contents {
    width: 100%;
  }
}

#map__contents__tiles {
  z-index: 50;
  grid-area: 1 / 1 / 4 / 4;
}

#map__contents__period {
  z-index: 100;
  padding: var(--space-l);
  grid-area: 3 / 2 / 4 / 3;
}

#map__contents__period .card {
  padding: 0;
  padding-left: var(--space-rel-s);
  align-items: center;
  gap: var(--space-rel-s);
  display: inline-flex;
}

#map__contents__period select {
  padding: var(--space-rel-s);
  border-top-right-radius: var(--card-border-radius);
  border-bottom-right-radius: var(--card-border-radius);
  font: inherit;
  color: #fff;
  height: 100%;
  background: var(--color-primary);
  border: none;
}

#map__contents__tools {
  z-index: 100;
  padding: var(--space-l);
  grid-area: 3 / 3 / 4 / 4;
}

#map__contents__details {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 100;
  width: 100%;
  background: #ffffffe6;
  flex-direction: column;
  grid-area: 2 / 1 / 4 / 4;
  display: flex;
  overflow: scroll;
}

#map__contents__details section {
  border: 1px solid red;
  display: contents;
}

@media (min-width: 900.001px) {
  #map__contents__details {
    grid-area: 2 / 1 / 4 / 2;
  }
}

#map__contents__details__topic {
  padding: var(--padding-default);
  margin: 0 auto;
  padding-bottom: 0;
}

#map__contents__details__topic ul {
  border: solid 2px var(--color-primary);
  border-radius: 5px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow: hidden;
}

#map__contents__details__topic li {
  width: 100%;
  color: var(--color-primary);
  background: var(--color-white);
}

#map__contents__details__topic li[data-selected] {
  background: var(--color-primary);
  color: var(--color-white);
}

#map__contents__details__topic a {
  width: 100%;
  text-align: center;
  padding: var(--space-rel-xs);
  color: inherit;
  text-decoration: none;
  display: inline-block;
}

#map__contents__details__graph {
  padding: var(--padding-default);
}

#map__contents__details__actions {
  background: var(--color-grey-01);
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

#map__contents__details__actions button {
  padding: var(--padding-default);
  filter: invert(67%) sepia(52%) saturate(614%) hue-rotate(176deg) brightness(90%) contrast(93%);
  background: none;
  border: none;
}

#map__contents__details__settings {
  background: var(--color-grey-01);
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#map__contents__details__settings li {
  padding: var(--space-m) var(--padding-default);
  border-bottom: solid 1px var(--color-grey-01);
  background: var(--color-grey-02);
}

#map__contents__details__settings select {
  padding: var(--space-rel-s);
  font: inherit;
  color: var(--color-black);
  background: var(--color-grey-01);
  border-radius: var(--card-border-radius);
  border: none;
}

#map__contents__details__drag-handle {
  background: var(--color-grey-02);
  height: var(--space-l);
  cursor: pointer;
  flex: none;
  display: block;
  position: relative;
}

#map__contents__details__drag-handle:after {
  content: "";
  width: 40%;
  height: 4px;
  min-width: 40px;
  top: var(--space-s);
  background: #0000001a;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 500.001px) {
  #map__contents__details__drag-handle {
    display: none;
  }
}

#map__contents__navigate {
  gap: var(--space-xs);
  z-index: 100;
  padding: var(--space-l);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #ffffffe6;
  grid-area: 1 / 1 / 2 / 4;
  display: flex;
}

@media (min-width: 900.001px) {
  #map__contents__navigate {
    grid-area: 1 / 1 / 2 / 2;
  }
}

#map__contents__navigate__search {
  gap: var(--space-xs);
  flex: auto;
  align-items: center;
  padding: 0;
  display: flex;
}

#map__contents__navigate__search label, #map__contents__navigate__search #map__contents__navigate__search__clear {
  padding: var(--space-rel-s);
}

#map__contents__navigate__search #map__contents__navigate__search__input {
  font: inherit;
  padding: var(--space-rel-s);
  width: 150px;
  background: none;
  border: none;
  flex: auto;
}

#map__contents__navigate__search #map__contents__navigate__search__input:focus {
  outline: none;
}

#map__contents__details__description {
  padding: var(--space-m) var(--padding-default);
  font: var(--font-small);
  color: var(--color-grey-03);
}

.graph-precip {
  --rect-width: 35px;
  --graph-height: 300px;
  --row-label-width: 35px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto var(--graph-height) auto;
  color: var(--color-black);
  font: var(--font-small);
  grid-template-areas: "header header"
                       "rowLabels graph"
                       ". columnLabels";
  gap: 0;
  display: grid;
}

.graph-precip .header {
  grid-area: header;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 2em;
  display: flex;
}

.graph-precip .header .legend {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.graph-precip .header .legend li:before {
  content: "";
  background: var(--color-primary);
  width: 1em;
  height: 1em;
  margin-right: var(--space-xs);
  display: inline-block;
}

.graph-precip .header .legend li:first-child:before {
  background: var(--color-primary-light);
}

.graph-precip .row-label-section {
  height: 100%;
  flex-direction: column-reverse;
  grid-area: rowLabels;
  justify-content: space-between;
  display: flex;
}

.graph-precip .row-label-section .row-label {
  width: var(--row-label-width);
  position: relative;
}

.graph-precip .row-label-section .row-label:nth-child(2n+1):after {
  content: attr(data-value);
  position: absolute;
  right: .5em;
  transform: translateY(-50%);
}

.graph-precip .row-lines-section {
  flex-direction: column;
  grid-area: graph;
  justify-content: space-between;
  display: flex;
}

.graph-precip .row-lines-section .row-line {
  height: 1px;
  width: 100%;
  background: #d3d3d3;
}

.graph-precip .column-label-section {
  flex-direction: row;
  grid-area: columnLabels;
  justify-content: space-around;
  display: flex;
}

.graph-precip .column-label-section .column-label {
  text-align: center;
  width: var(--rect-width);
}

.graph-precip .column-label-section .column-label:after {
  content: "y";
}

.graph-precip .graph-section {
  height: 100%;
  pointer-events: none;
  flex-direction: row;
  grid-area: graph;
  justify-content: space-around;
  align-self: end;
  display: flex;
}

.graph-precip .graph-section .graph-item {
  color: #fff;
  width: var(--rect-width);
  text-align: center;
  pointer-events: auto;
  cursor: pointer;
  background: none;
  align-self: flex-end;
  position: relative;
}

.graph-precip .graph-section .graph-item:after {
  content: "";
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, #fff6 0%, #0000 100%);
  transition: all .4s ease-out;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.graph-precip .graph-section .graph-item:hover {
  z-index: 10;
}

.graph-precip .graph-section .graph-item:hover:after {
  opacity: 0;
}

.graph-precip .graph-section.graph-section .climate-item {
  background: var(--color-primary-light);
}

.graph-precip .graph-section.graph-section-layer .graph-item {
  transform: translateX(calc(var(--rect-width) * .2) );
}

.graph-precip .graph-section.graph-section-layer .present-item {
  background: var(--color-primary);
}

.graph-precip .graph-section.graph-section-layer .graph-item .uncert-item {
  border-top: 1px solid #17527c;
  margin-left: 8px;
  margin-right: 8px;
  box-shadow: none !important;
}

.graph-precip .graph-section.graph-section-layer .graph-item .overlap-item {
  box-shadow: var(--card-shadow);
  background-color: var(--color-primary);
}

.graph-precip .graph-section.graph-section-layer .graph-item .overlap-item-span {
  width: 21px;
  border-top: 1px solid #17527c;
  margin-bottom: 14px;
  display: inline-block;
}

.leaflet-marker-icon {
  filter: invert(64%) sepia(49%) saturate(651%) hue-rotate(347deg) brightness(96%) contrast(97%);
}

#aside #home {
  height: 100%;
  flex-direction: column;
  display: flex;
}

#aside #home article {
  flex: 1;
}

#aside #home #legend {
  width: 100%;
}

#aside #home #legend__scale {
  padding: 0 var(--space-xs);
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  justify-content: space-between;
  display: flex;
}

#aside #home #legend__colors {
  margin-top: var(--space-s);
  height: var(--space-xl);
  background: linear-gradient(90deg, #ffffd9 0%, #c7e9b4 25%, #41b6c4 50%, #225ea8 75%, #1d2e5f 100%);
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

@media (min-width: 500.001px) {
  #app[data-aside-closed] #map__contents__navigate {
    grid-area: 1 / 1 / 2 / 2;
  }

  #app[data-aside-closed] #map__contents__details {
    grid-area: 2 / 1 / 4 / 2;
  }
}

#map[data-details-closed] #map__contents__navigate {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  background: none;
}

#map[data-details-closed] #map__contents__navigate__search__clear, #map[data-details-closed] #map__contents__details {
  display: none;
}

#map[data-details-closed] #map__contents__period {
  grid-area: 3 / 1 / 4 / 2;
}

@media screen and (max-width: 500px) {
  #map[data-details-as-sheet] #map__contents__navigate {
    background: none;
  }

  #map[data-details-as-sheet] #map__contents__details {
    --sheet-height: 400px;
    box-shadow: var(--card-shadow);
    top: calc(100% - var(--sheet-height));
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
  }

  #map[data-details-as-sheet] .graph-precip {
    --graph-height: 180px;
  }

  #map[data-details-as-sheet] #map__contents__details__description {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  #app {
    height: 100vh;
    overflow-y: hidden;
  }
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  width: 26px;
  height: 26px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  width: 36px;
  height: 36px;
  background-image: url("layers.760a0456.png");
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  pointer-events: none;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  pointer-events: auto;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  width: 24px;
  height: 24px;
  color: #757575;
  background: none;
  border: none;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/*# sourceMappingURL=index.a7937871.css.map */
